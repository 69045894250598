import { AnyAction } from 'redux';
import { put, select, call } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { push } from 'connected-react-router';
import { throwSubmissionError } from '@/utils';
import { CREDIT_CARD, ADD_OR_CONTINUE_TRIAL_CLICKED } from '@/utils/constants';
import Notifications from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';
import qs from 'qs';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import { orderBy } from 'lodash';
import { ApplicationState } from '@/store';
import { confirmSaga } from './confirm';
import { Company, CompanyType } from '@/types';

export function* fetchLicenseTypes({ payload }: AnyAction) {
    try {
        const language = window.localStorage.getItem('language')?.toUpperCase();
        const countryId =
            language === 'SV' ? 'SE' : window.localStorage.getItem('language')?.toUpperCase();

        const response: AxiosResponse = yield apiClient.get('/licenses/types', {
            params: {
                IncludeLicenseItems: false,
                IncludeLicensePrices: true,
                CountryId: countryId,
                ...payload,
            },
        });

        yield put(actions.FETCH_LICENSE_TYPES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_LICENSE_TYPES.failure(error));
    }
}

export function* fetchLicenseInformation() {
    try {
        const response: AxiosResponse = yield apiClient.get('/licenses/information');

        yield put(actions.FETCH_LICENSE_INFO.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_LICENSE_INFO.failure(error));
    }
}

export function* fetchCompanyLicenses({ payload = {} }: AnyAction) {
    try {
        yield put(actions.FETCH_CREDIT_CARD.request());
        const response: AxiosResponse = yield apiClient.get('/licenses/company', {
            params: { ...payload },
        });

        const orderedData = orderBy(response.data, 'Active');

        yield put(actions.FETCH_COMPANY_LICENSES.success(orderedData));
    } catch (error) {
        yield put(actions.FETCH_COMPANY_LICENSES.failure(error));
    }
}

export function* createLicense({ payload }: AnyAction) {
    const company: Company | undefined = yield select((state: ApplicationState) => state.company.data);
    const localizeState: RootState['localize'] = yield select((state) => state.localize);
    const translate = getTranslate(localizeState);


    try {
        const localizeState: RootState['localize'] = yield select((state) => state.localize);
        const translate = getTranslate(localizeState);

        const response: AxiosResponse = yield apiClient.post('/licenses/company', payload);

        yield put(actions.CREATE_COMPANY_LICENSE.success(response.data));

        const licenseTypeId = payload.TypeId;
        let subscriptionType;

        if (licenseTypeId === 1) {
            subscriptionType = 'FreeEdition';
        } else if (licenseTypeId === 2) {
            subscriptionType = 'StandardEdition';
        } else if (licenseTypeId === 3) {
            subscriptionType = 'ProfessionalEdition';
        } else if (licenseTypeId === 5) {
            subscriptionType = 'BusinessEdition';
        } else if (licenseTypeId === 9) {
            subscriptionType = 'EnterpriseEdition';
        }

        // @ts-ignore
        window?.dataLayer.push({
            event: 'accountUpgrade',
            subscriptionType,
            ...(company?.Email ? {
                email: company.Email,
                phone: company.Phone
            } : {})
        });

        if (payload.BillingInformation.BillingMethodId !== CREDIT_CARD) {
            yield put({ type: actions.HIDE_LICENSES_MODAL });
            yield put(
                Notifications.show({
                    level: 'success',
                    title: translate('licenses.successfullyUpgradedLicenseTitle') as string,
                    message: translate('licenses.successfullyUpgradedLicense') as string,
                })
            );
        } else {
            if (true) {
                yield put(
                    push({
                        pathname: '/billing/add-credit-card',
                        search: qs.stringify(payload.BillingInformation),
                    })
                );
            }
        }
    } catch (error: any) {        
        yield put(actions.CREATE_COMPANY_LICENSE.failure(throwSubmissionError(error)));

        // Show a modal prompting to go to my subscriptions because license is not enough
        if (
            error.response?.data?.ResponseStatus?.Errors[0].Meta.PropertyName === 'Type Id' &&
            error.response?.data?.ResponseStatus?.Errors[0].Meta.PropertyValue === '11'
        ) {
            const responesErrorMessage = `
                <p>${error.response?.data?.ResponseStatus.Message}</p>
                <p>${translate('licenses.goToMySubscriptionsPrompt')}</p>
            `;
            const confirmed: boolean = yield call(confirmSaga, responesErrorMessage);
            if (confirmed) {
                yield put({
                    type: actions.HIDE_LICENSES_MODAL,
                });
                yield put(push('/account'));
            }
        }
    }
}

export function* cancelLicense({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.delete(`/licenses/company/${payload}`);

        yield put(actions.CANCEL_LICENSE.success(response.data));
        yield put(actions.FETCH_COMPANY_LICENSES.request());
    } catch (error) {
        yield put(actions.CANCEL_LICENSE.failure(error));
    }
}

export function* cancelAllLicenses({ payload }: AnyAction) {
    try {
        const questionarieData: { [key in string]: any } = yield select(
            (s: ApplicationState) => s.cancelLicenseQuestionaire.data
        );

        const questionaryFields = Object.entries(questionarieData).reduce((acc, curr, index, arr) => {
            if(index > 0 && arr[index - 1].includes('main')) {
                acc.push({
                    Question: arr[index - 1][1],
                    Value: curr[1][1]
                })
            } else if(!curr.includes('main')) {
                acc.push({
                    Question: curr[0],
                    Value: curr[1]
                })
            }

            return acc;
        }, [] as { Question: any, Value: any }[]);

        const response: AxiosResponse = yield apiClient.post(`/licenses/company/delete`, {
            Questionnaire: questionaryFields,
            ...payload
        });

        // @ts-ignore
        window?.dataLayer.push({
            event: 'accountClosure',
        });

        yield put(actions.CANCEL_ALL_LICENSES.success(response.data));
        yield put(push('/'));
    } catch (error) {
        yield put(actions.CANCEL_ALL_LICENSES.failure(error));
    }
}

export function* checkDomain({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/licenses/checkdomain`, {
            params: payload,
        });

        yield put(actions.FETCH_CHECK_DOMAIN.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_CHECK_DOMAIN.failure(error));
    }
}

// ===============================
// Trials
// ===============================
export function* trials({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/trials`, { params: payload });

        yield put(actions.FETCH_TRIALS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_TRIALS.failure(error));
    }
}

export function* addTrial({ payload }: AnyAction) {
    try {

        const response: AxiosResponse = yield apiClient.post(`/trials/company`, payload);

        yield put(actions.ADD_TRIAL.success(response.data));
        yield put({ type: actions.HIDE_LICENSES_MODAL });
        const localizeState: RootState['localize'] = yield select((state) => state.localize);
        yield put(
            // @ts-ignore
            Notifications.success(getTranslate(localizeState)('licenses.trialHasBeenStarted'))
        );
        sessionStorage.setItem(ADD_OR_CONTINUE_TRIAL_CLICKED, 'true');
    } catch (error) {
        yield put(actions.ADD_TRIAL.failure(error));
    }
}

export function* companyTrials({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/trials/company`, { params: payload });

        yield put(actions.FETCH_COMPANY_TRIALS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_COMPANY_TRIALS.failure(error));
    }
}

export function* checkTrial({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/trials/company/check`, {
            params: payload,
        });

        yield put(actions.FETCH_CHECK_TRIAL.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_CHECK_TRIAL.failure(error));
    }
}

export function* fetchLicenseInformationTypes() {
    try {
        const response: AxiosResponse = yield apiClient.get('/licenses/informationtypes');

        yield put(actions.FETCH_LICENSE_INFORMATION_TYPES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_LICENSE_INFORMATION_TYPES.failure(error));
    }
}

export function* fetchLicensePlans() {
    try {
        const response: AxiosResponse = yield apiClient.get('/licenses/plans');

        yield put(actions.FETCH_LICENSE_PLANS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_LICENSE_PLANS.failure(error));
    }
}
