import React from 'react';
import styled from 'styled-components';
import { connect, ConnectedComponent } from 'react-redux';
import { FormSection, change } from 'redux-form';
import { Button, Grid, Checkbox, Loader as Spinner } from 'semantic-ui-react';
import { getTranslate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import Field from './Field';
import Form from './Form';
import Loader from './Loader';
import * as actions from '../store/actions';
import { Company, EntityState, LicensePlans } from '../types';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '@/store';

const OptionDescription = styled.div`
    font-size: 0.9 em;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 5px;
`;

interface Props extends ConnectedComponent<any, any>, RouteComponentProps {
    company: EntityState<Company>;
    preventRequiredActions?: string[];
    autofill?: boolean;
    modal: boolean;
    licensePlans: EntityState<LicensePlans['Results']>
}

interface State {}

class BillingForm extends React.Component<Props, State> {
    state = {
        useCompanyAddress: false,
    };

    componentDidMount() {
        this.props.dispatch(actions.FETCH_BILLING_SETTINGS.request());
    }
    

    componentDidUpdate(prevProps: Props) {
        const { company } = this.props;

        if (!prevProps.company.isLoaded && company.isLoaded) {
            this.polulateCompanyAddress(true);
            this.props.dispatch(actions.FETCH_BILLING_METHODS.request(company.data.CountryId));
        }
    }

    toggleUseCompanyAddress = () => {
        const newValue = !this.state.useCompanyAddress;
        this.setState({ useCompanyAddress: newValue });
        this.polulateCompanyAddress(newValue);
    };

    polulateCompanyAddress = (useCompanyAddress: boolean) => {
        const fieldsMap = {
            Email: 'Email',
            Street1: 'Street1',
            Street2: 'Street2',
            ZipCode: 'ZipCode',
            City: 'City',
            CountryId: 'Country',
            Name: 'Name'
        };

        Object.keys(fieldsMap).forEach((key) => {
            this.props.dispatch(
                change(
                    'billing',
                    `BillingInformation.${key}`,
                    // @ts-ignore
                    useCompanyAddress ? this.props.company.data[fieldsMap[key]] : null
                )
            );
        });
    };

    render() {
        const props = this.props;
        const { translate, formValues, billingMethods, submitting, licensePlans, invalid } = props;
        const method =
            formValues && formValues.BillingInformation
                ? formValues.BillingInformation.BillingMethodId
                : null;
        const licensePlanId = formValues && formValues.BillingInformation
            ? formValues.BillingInformation.LicensePlanId
            : null; 

        const isFirstStepCompleted = method && licensePlanId;
        
        const showEmailField = method === 2 || method === 3;
        const { useCompanyAddress } = this.state;

        return this.props.billingSettings.isLoaded || this.props.billingSettings.hasError ? (
            <FormSection name="BillingInformation">
                <Grid>
                    <Grid.Column computer={8} mobile={16}>
                        <Field
                            name="BillingMethodId"
                            label={translate('billing.BillingMethodId')}
                            placeholder={translate('billing.BillingMethodId')}
                            type="select"
                            required
                            options={billingMethods.data.map((item: any) => ({
                                key: item.Id,
                                value: item.Id,
                                text: item.Name,
                                content: (
                                    <div key={item.Id}>
                                        <div>{item.Name}</div>
                                        <OptionDescription>{item.Description}</OptionDescription>
                                    </div>
                                ),
                            }))}
                        />
                        <Field
                            name="LicensePlanId"
                            label={translate('billing.licensePlan')}
                            placeholder={translate('billing.licensePlan')}
                            type="select"
                            required
                            options={licensePlans.data?.map((item) => {
                                if (!item.VossPlan) {
                                    return {
                                        key: item.Id,
                                        value: item.Id,
                                        text: translate('billing.licensePlans.monthly.name'),
                                    };
                                }

                                return {
                                    key: item.Id,
                                    value: item.Id,
                                    text: item.VossPlan?.Name,
                                    content: (
                                        <div key={item.Id}>
                                            <div>{item.VossPlan?.Name}</div>
                                        </div>
                                    ),
                                };
                            })}
                        />
                        {isFirstStepCompleted ? (
                            <>
                                <Checkbox
                                    checked={useCompanyAddress}
                                    onChange={this.toggleUseCompanyAddress}
                                    label={translate('billing.companydata')}
                                />
                                <Field
                                    name="Name"
                                    label={translate('billing.BillingCompanyName')}
                                    placeholder={translate('billing.BillingCompanyName')}
                                    type="text"
                                    required
                                />
                                <Field
                                    name="Attention"
                                    label={translate('billing.Attention')}
                                    placeholder={translate('billing.Attention')}
                                    type="text"
                                />
                                {showEmailField && (
                                    <Field
                                        name="Email"
                                        label={translate('billing.BillingEmail')}
                                        placeholder={translate('billing.BillingEmail')}
                                        type="email"
                                        required
                                    />
                                )}
                                {method !== 1 && method !== 2 ? (
                                    <Field
                                        name="GLN"
                                        label={translate('billing.GLN')}
                                        placeholder={translate('billing.GLN')}
                                        type="text"
                                    />
                                ) : null}
                                {method !== 1 && method !== 2 ? (
                                    <Field
                                        name="ReferenceLine1"
                                        label={translate('billing.ReferenceLine1')}
                                        placeholder={translate('billing.ReferenceLine1')}
                                        type="text"
                                    />
                                ) : null}
                                {method !== 1 && method !== 2 ? (
                                    <Field
                                        name="ReferenceLine2"
                                        label={translate('billing.ReferenceLine2')}
                                        placeholder={translate('billing.ReferenceLine2')}
                                        type="text"
                                    />
                                ) : null}
                            </>
                        ) : null}
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        {isFirstStepCompleted && (
                            <>
                                <Field
                                    name="Street1"
                                    label={translate('billing.BillingStreet1')}
                                    placeholder={translate('billing.BillingStreet1')}
                                    type="text"
                                    required
                                />
                                <Field
                                    name="Street2"
                                    label={translate('billing.BillingStreet2')}
                                    placeholder={translate('billing.BillingStreet2')}
                                    type="text"
                                />
                                <Field
                                    name="ZipCode"
                                    label={translate('billing.BillingPostalCode')}
                                    placeholder={translate('billing.BillingPostalCode')}
                                    type="text"
                                    required
                                />
                                <Field
                                    name="City"
                                    label={translate('billing.BillingCity')}
                                    placeholder={translate('billing.BillingCity')}
                                    type="text"
                                    required
                                />
                                <Field
                                    name="CountryId"
                                    label={translate('billing.CountryId')}
                                    placeholder={translate('billing.CountryId')}
                                    type="select"
                                    options={[
                                        { key: 'SE', value: 'SE', text: 'Sweden' },
                                        { key: 'DK', value: 'DK', text: 'Denmark' },
                                        { key: 'FI', value: 'FI', text: 'Finland' },
                                        { key: 'NO', value: 'NO', text: 'Norway' },
                                    ]}
                                    required
                                />
                                <Field
                                    name="VatRegistrationNumber"
                                    label={translate('billing.VatRegistrationNumber')}
                                    placeholder={translate('billing.VatRegistrationNumber')}
                                    type="text"
                                    required
                                />
                            </>
                        )}
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {props.onBack && (
                            <Button
                                data-testid="billform-go-back"
                                type="button"
                                content={props.translate('form.back')}
                                onClick={props.onBack}
                            />
                        )}
                        <Button
                            type="submit"
                            data-testid="billform-submit"
                            primary
                            content={props.translate('form.save')}
                            floated="right"
                            loading={submitting}
                            disabled={invalid}
                        />
                    </Grid.Column>
                </Grid>
            </FormSection>
        ) : (
            <Spinner />
        );
    }
}

const validate = (values: any, props: any) => {
    const errors = { BillingInformation: {} };
    const { translate } = props; //I added this line to be able to use translations
    if (!values.BillingInformation || !values.BillingInformation.BillingMethodId) {
        // @ts-ignore
        errors.BillingInformation.BillingMethodId = translate('billing.youMustSelectBillingMethod');
    }
    return errors;
};

class BillingContainer extends React.Component<Props> {
    static defaultProps = {
        // Since this form is mostly used in purchasing a license flow
        // use this action as default
        action: actions.CREATE_COMPANY_LICENSE,
    };

    goToLicensesPage = () => {
        const { pathname } = this.props.location;
        const { modal } = this.props;

        if(pathname !== '/setup-account' && !modal) {
            this.props.dispatch(push('/account'));
        }
    };

    render() {
        const { action, billingSettings, creditCard, company, licenseTypeId, meta, billingInformation, autofill, licensePlans, billingMethods } = this.props;
        
        return (
            <Loader
                requirements={[
                    {
                        action: actions.FETCH_CREDIT_CARD.request(),
                        data: creditCard,
                    },
                    {
                        action: actions.FETCH_COMPANY.request(),
                        data: company,
                    },
                    {
                        action: actions.FETCH_BILLING_METHODS.request(),
                        data: billingMethods,
                    },
                    {
                        action: actions.FETCH_LICENSE_PLANS.request(),
                        data: licensePlans,
                    },
                ].filter(
                    (requirement) =>
                        !this.props.preventRequiredActions?.includes(requirement.action.type)
                )}
            >
                <Form
                    name="billing"
                    initialValues={{
                        MetaData: meta,
                        TypeId: licenseTypeId,
                        BillingInformation: {
                            CountryId: company?.data?.CountryId,
                            ...(autofill &&
                            company?.data?.CountryId === 'SE' &&
                            (!billingInformation?.CountryId || billingInformation?.CountryId === 'SE') &&
                            !billingSettings?.data?.VatRegistrationNumber
                                ? {
                                      VatRegistrationNumber: `SE${company.data.OrganisationNumber?.replace('-','')}01`,
                                  }
                                : { VatRegistrationNumber: billingSettings?.data?.VatRegistrationNumber ?  billingSettings?.data?.VatRegistrationNumber : ''}),
                            ...billingSettings.data,
                        },
                    }}
                    action={action}
                    showActionBar={false}
                    validate={validate}
                    customOnSubmitSuccess={this.props.onSubmitSuccess || this.goToLicensesPage}
                >
                    {(formProps: any) => <BillingForm {...this.props} {...formProps} />}
                </Form>
            </Loader>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: Props) => {
    const { billing, company, appModal, licenses } = state;
    const { creditCard } = state.licenses;

    return {
        company,
        creditCard,
        licensePlans: licenses.licensePlans,
        billingMethods: billing.methods,
        billingSettings: billing.settings,
        billingInformation: state.form?.billing?.values?.BillingInformation,
        translate: getTranslate(state.localize),
        modal: appModal.open || ownProps.modal
    };
};

export default compose(
    connect(mapStateToProps),
    withRouter
)(BillingContainer) as React.ComponentType<any>;
